import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./App.css";
import React from "react";
import { useTable } from "react-table";
import {
	LineChart,
	ResponsiveContainer,
	Legend, Tooltip,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	BarChart,
	Bar
} from 'recharts';
import { useEffect, useState } from "react";
import moment from 'moment'

// let internet_data = [
//   {
//     timestamp: "2022-12-12",
//     value: 0.12
//   },
//   {
//     timestamp: "2022-12-13",
//     value: 0.42
//   },
//   {
//     timestamp: "2022-12-14",
//     value: 0.52
//   }
// ];

function maker(internet, attack_data) {
	return { attack_data: attack_data, internet_data: internet }
}

class App extends React.Component {
	// Constructor 
	constructor(props) {
		super(props);

		this.state = {
			internet: [],
			attack: [],
			pop_doms: [],
			DataisLoaded: 0
		};
	}

	// ComponentDidMount is used to
	// execute the code 
	componentDidMount() {
		fetch(
			"https://dashboard.dashboard-kv.workers.dev/traffic-change")
			.then((res) => res.json())
			.then((data) => {
				let internet_data = []
				let total_data = JSON.parse(data["data"]["total"]);
				let http_data = JSON.parse(data["data"]["http"]);
				for (let i = 0; i < total_data.length; i++) {
					let d = {
						timestamp: Date.parse(total_data[i]["timestamp"]),
						Total: parseFloat((total_data[i]["value"])),
						Http: parseFloat((http_data[i]["value"]))
					};
					internet_data.push(d)
				}
				this.setState({
					internet: internet_data,
					DataisLoaded: this.state.DataisLoaded + 1
				});
				return internet_data;
			})
		fetch(
			"https://dashboard.dashboard-kv.workers.dev/attack-layer3")
			.then((res) => res.json())
			.then((data) => {
				let attack_data = []
				let total_data = JSON.parse(data["data"]["total"]);
				for (let i = 0; i < total_data.length; i++) {
					let d = {
						timestamp: Date.parse(total_data[i]["timestamp"]),
						Total: parseFloat((total_data[i]["value"])),
					};
					attack_data.push(d)
				}
				this.setState({
					attack: attack_data,
					DataisLoaded: this.state.DataisLoaded + 1
				});
				return attack_data;
			})

		fetch(
			"https://dashboard.dashboard-kv.workers.dev/popular-domains")
			.then((res) => res.json())
			.then((data) => {
				this.setState({
					pop_doms: JSON.parse(data),
					DataisLoaded: this.state.DataisLoaded + 1
				});
				return data;
			})
	}

	render() {
		const { DataisLoaded, internet, attack, pop_doms } = this.state;
		let columns = ["rank"]
		if (pop_doms.length > 0) {
			console.log(pop_doms[0]["domain"])
		}
		return (
			<div className="App">
				<Tabs className="Tabs">
					<TabList>
						<Tab>Traffic Change</Tab>
						<Tab>Popular Domains</Tab>
						<Tab>Layer 3 DDOS Attack</Tab>
					</TabList>
					<TabPanel>
						<h4 className="text-heading">
							Traffic Changes
						</h4>
						<ResponsiveContainer width="100%" aspect={3}>
							<LineChart
								width={1500}
								data={internet}
							>
								<Line type="monotone" dataKey="Total" stroke="#8db0e5" dot={false} />
								<Line type="monotone" dataKey="Http" stroke="#AFE1AF" dot={false} />
								<Tooltip labelFormatter={t => new Date(t).toLocaleString()} formatter={(val) => Math.round(val * 10000) / 100 + "%"} />
								<Legend iconSize={1} />
								<XAxis
									dataKey='timestamp'
									domain={['auto', 'auto']}
									name='Time'
									tickFormatter={(unixTime) => moment(unixTime).format('HH:00 MM/DD')}
									type='number'
									tickLine={{ stroke: '#000000' }}
									tick={{ fontSize: 10, fill: '#000000' }}
									tickCount={10}
									angle={-45}
									dx={5}
									dy={15}
									height={50}

								/>
								<YAxis
									tickLine={{ stroke: '#000000' }}
									tick={{ fontSize: 10, fill: '#000000' }} />

							</LineChart>
						</ResponsiveContainer>

					</TabPanel>
					<TabPanel>
						<p>Popular Domains</p>
						{
							<table class="center">
								<tr>
									<th>
										Rank
									</th>
									<th>
										Domain
									</th>
									<th>
										Rank Change
									</th>
									<th>
										Technology
									</th>
								</tr>
								{
									pop_doms.map(
										item => {
											return <tr>
											<td> {item["rank"] } </td> 
											<td>  {item["domain"]}  </td> 
											<td>  {item["rankChange"]}  </td> 
											<td>  {item["category"]}  </td> 
											 </tr>
										}
									)
								}
							</table>
						}
					</TabPanel>
					<TabPanel>
						<h4 className="text-heading">
							Layer 3 DDOS Attack
						</h4>
						<ResponsiveContainer width="100%" aspect={3}>
							<LineChart
								width={1500}
								data={attack}
							>
								<Line label="total" type="monotone" dataKey="Total" stroke="#8db0e5" dot={true} />
								<Tooltip labelFormatter={t => new Date(t).toLocaleString()} formatter={(val) => Math.round(val * 10000) / 100 + "%"} />
								<Legend iconSize={1} />
								<XAxis
									dataKey='timestamp'
									domain={['auto', 'auto']}
									name='Time'
									tickFormatter={(unixTime) => moment(unixTime).format('HH:00 MM/DD')}
									type='number'
									tickLine={{ stroke: '#000000' }}
									tick={{ fontSize: 10, fill: '#000000' }}
									tickCount={10}
									angle={-45}
									dx={5}
									dy={15}
									height={50}

								/>
								<YAxis
									tickLine={{ stroke: '#000000' }}
									tick={{ fontSize: 10, fill: '#000000' }} />

							</LineChart>
						</ResponsiveContainer>

					</TabPanel>
				</Tabs>
			</div>
		);
	}

}

export default App;